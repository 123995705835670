export const capitalize = <T extends string>(s: T): Capitalize<T> =>
	(s && (s.charAt(0).toUpperCase() + s.slice(1))) as Capitalize<T>;

export const uncapitalize = <T extends string>(s: T): Uncapitalize<T> =>
	(s && (s.charAt(0).toLowerCase() + s.slice(1))) as Uncapitalize<T>;

export const removeSpaces = (text: string): string => text.replace(/\s/g, "");

export const removeNonDigit = (text: string): string => text.replace(/\D/g, "");

// Remove all non digits apart from the first character which can be a + sign
export const removeNonPhoneNumberCharacters = (text: string): string => /^[\d+]$/.test(text.charAt(0)) ? text[0] + removeNonDigit(text.slice(1)) : text.slice(1);

// Transforms a string with accent to the equivalent without accent, for example Crème brulée -> Creme brulee
// https://stackoverflow.com/a/37511463
export const unaccent = (text: string): string => text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
