import * as Notifications from "expo-notifications";
import * as Updates from "expo-updates";
import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Account} from "../@types/accounts";
import {Corporation} from "../@types/identities/corporation";
import {Interpreter} from "../@types/identities/interpreter";
import {NotificationsStatus, NotificationTarget} from "../@types/notifications";
import {InterpreterSetting, RequesterSetting, Setting} from "../@types/settings";
import {alert} from "../components/feedbacks/alert";
import {toast} from "../components/feedbacks/toast";
import {Spacer} from "../components/spacer";
import {Text} from "../components/texts/text";
import {isPushChannel} from "../navigation/screens/common/modals/select-notification-target";
import {loginClient} from "../requests/clients/account/login";
import {getCorporation} from "../requests/clients/corporation";
import {getClientNotificationTargets, updateClientNotificationTarget} from "../requests/clients/settings/notifications";
import {getClientSettings, updateClientSetting} from "../requests/clients/settings/settings";
import {loginInterpreter} from "../requests/interpreters/account/login";
import {getInterpreter} from "../requests/interpreters/interpreter";
import {
	deleteInterpreterNotificationTarget,
	getInterpreterNotificationTargets,
	updateInterpreterNotificationTarget,
} from "../requests/interpreters/settings/notifications";
import {getInterpreterSettings, updateInterpreterSetting} from "../requests/interpreters/settings/settings";
import {Auth, removeAuth as removeAuthUtil, setAuth} from "../utils/auth";
import {AUTO_ADD_NOTIFICATION_TARGET, DEFAULT_SPACING, IS_INTERPRETER, SMALL_SPACING} from "../utils/constants";
import {AsyncStorage} from "../utils/externals/storages/async-storage";
import {useTranslation} from "../utils/hooks/use-translation";
import {TranslationFeedbackKey} from "../utils/locales/translations";
import {Log} from "../utils/logs/logs";
import {NotificationManager, onNotification} from "../utils/notifications/manager";
import {ReceivedNotification} from "../utils/notifications/manager.common";
import {defaultPagination} from "../utils/pagination";
import {unaccent} from "../utils/strings";
import {LocaleContext} from "./locale";

interface SettingsContext {
	errorMessage?: TranslationFeedbackKey;
	getSetting: <S extends Setting>(key: S["key"], context: S["context"]) => S | undefined;
	loading: boolean;
	updateSetting: (updated: Setting) => Promise<void>;
}

export interface Authentified {
	accountId: Account["accountId"];
	authToken: string;
	identity: Corporation | Interpreter;
}

interface AuthentifiedContextVal extends Partial<Authentified> {
	checkAuth: () => Promise<void>;
	notificationsStatus?: NotificationsStatus;
	replacePushDevice: (accountId: Authentified["accountId"]) => Promise<void>;
	requestNotificationsPermissions: () => Promise<void>;
	setAuthentified: React.Dispatch<React.SetStateAction<Authentified | null>>;
	settings: SettingsContext;
	signIn: (username: string, password: string) => Promise<void>;
	signOut: () => Promise<void>;
}

export const AuthentifiedContext = React.createContext<AuthentifiedContextVal>({} as AuthentifiedContextVal);
export const AuthentifiedProvider = ({children, newOta}: React.PropsWithChildren<{newOta: boolean}>): JSX.Element => {
	const {locale} = React.useContext(LocaleContext);
	const {ct} = useTranslation();
	const [authentified, setAuthentified] = React.useState<Authentified | null>(null);
	const [loading, setLoading] = React.useState(true);
	const [errorMessage, setErrorMessage] = React.useState<TranslationFeedbackKey>();
	const [settings, setSettings] = React.useState<Setting[]>([]);
	const [notificationsStatus, setNotificationsStatus] = React.useState<NotificationsStatus>();
	const lastNotificationResponseId = React.useRef<string>();
	const responseListener = React.useRef<Notifications.Subscription | null>();

	// Check for OTA updates
	React.useEffect(
		() => {
			if (newOta) {
				alert({
					actions: [
						{
							key: "later",
							onPress: () => null,
							text: ct("common:later"),
							type: "secondary",
						},
						{
							key: "restart",
							onPress: () => Updates.reloadAsync(),
							text: ct("common:restart"),
						},
					],
					content: (
						<View style={styles.alertContainer}>
							<Text type="button_1" centered>{ct("screens:feedback.otaTitle")}</Text>
							<Spacer size={SMALL_SPACING} mode="horizontal"/>
							<Text centered>{ct("screens:feedback.otaSubtitle")}</Text>
						</View>
					),
				});
			}
		},
		[ct, newOta],
	);

	React.useEffect(
		() => {
			if (authentified) {
				responseListener.current = NotificationManager.subscribeNotification();
			} else {
				responseListener.current && NotificationManager.unsubscribeNotification();
			}
			return () => {
				responseListener.current && NotificationManager.unsubscribeNotification();
			};
		},
		[authentified],
	);

	const signOut = React.useCallback(
		() => {
			setLoading(true);
			return removeAuthUtil()
				.then(() => setAuthentified(null))
				.finally(() => setLoading(false));
		},
		[setAuthentified],
	);

	const createNotificationsTarget = React.useCallback(
		() => (IS_INTERPRETER
			? updateInterpreterNotificationTarget
			: updateClientNotificationTarget
		)({
			...NotificationManager.getThisTargetInfos(),
			notified: true,
		}),
		[],
	);

	const deleteOtherPushDevices = React.useCallback(
		(accountId: Authentified["accountId"], pushDevices: NotificationTarget[]) =>
			Promise.all(
				pushDevices
					.filter(notificationTarget =>
						isPushChannel(notificationTarget.channel) &&
						notificationTarget.id !== NotificationManager.getThisTargetInfos().id,
					)
					.map(pushDevice =>
						deleteInterpreterNotificationTarget(accountId, pushDevice.id),
					),
			),
		[],
	);

	const navigateOnNotificationClick = React.useCallback(
		() =>
			NotificationManager.getLastNotificationResponse()
				.then(lastNotificationResponse => {
					// redirect to right screen (only on first notification click)
					if (
						!!lastNotificationResponse &&
						lastNotificationResponseId.current !== lastNotificationResponse.notification.request.identifier
					) {
						lastNotificationResponseId.current = lastNotificationResponse.notification.request.identifier;
						onNotification(lastNotificationResponse as unknown as ReceivedNotification);
					}
				})
				.catch(Log.error()),
		[],
	);

	const configureNotificationTarget = React.useCallback(
		(accountId: Authentified["accountId"]) =>
			// check and request permissions on login
			NotificationManager.checkPermissions()
				.then((notificationsPermissions) =>
					notificationsPermissions || NotificationManager.requestPermissions(),
				).then(notificationsPermissions => {
					if (notificationsPermissions) {
						if (AUTO_ADD_NOTIFICATION_TARGET) {
							return (IS_INTERPRETER
								? getInterpreterNotificationTargets
								: getClientNotificationTargets)(defaultPagination)
								.then(notificationTargets => {
									const pushDevices = notificationTargets.items.filter(notificationTarget =>
										isPushChannel(notificationTarget.channel));

									const isTarget = pushDevices.some(pushDevice => pushDevice.id === NotificationManager.getThisTargetInfos().id);

									// For interpreters, we limit to one notification target for push notifications
									if (IS_INTERPRETER) {
										if (pushDevices.length === 1) {
											setNotificationsStatus(isTarget ? "granted" : "anotherDevice");
										} else {
											if (pushDevices.length > 1) {
												deleteOtherPushDevices(accountId, pushDevices).catch(Log.error());
											}
											if (!isTarget) {
												setTimeout(
													() => {
														createNotificationsTarget().catch(Log.error());
													},
													600,
												);
											}
											setNotificationsStatus("granted");
										}
									} else {
										setNotificationsStatus("granted");
									}
								});
						}
					} else {
						setNotificationsStatus("denied");
					}
				}),
		[createNotificationsTarget, deleteOtherPushDevices],
	);

	const checkAuth = React.useCallback(
		() => AsyncStorage.getItems(["AuthToken", "AccountId"])
			.then(([authToken, accountId]) => {
				if (!authToken || !accountId) {
					Log.debug("getDataFailed");
					signOut().catch(Log.error());
				} else {
					setAuth(authToken, accountId, {asyncStorage: false})
						.then(() => (IS_INTERPRETER ? getInterpreter : getCorporation)(accountId)
							.then(identity => {
								setAuthentified({accountId, authToken, identity});
								(IS_INTERPRETER
									? getInterpreterSettings
									: getClientSettings)(accountId)
									.then(setSettings)
									.catch((error) => {
										Log.error(IS_INTERPRETER ? "getInterpreterSettingsFailed" : "getUserSettingsFailed")(error);
										// TODO add a reload button in the SplashView when it displays an error
										setErrorMessage(
											IS_INTERPRETER ? "feedbacks:getInterpreterSettingsFailed" : "feedbacks:getUserSettingsFailed");
									});
								AsyncStorage.setItem("LastLogin", new Date().toISOString()).catch(Log.error());

								navigateOnNotificationClick().catch(Log.error());
								configureNotificationTarget(accountId).catch(Log.error());
							})
							.catch(() => signOut().catch(Log.error())),
						)
						.finally(() => setLoading(false));
				}
			})
			.catch(Log.error()),
		[configureNotificationTarget, navigateOnNotificationClick, signOut],
	);

	React.useEffect(
		() => {
			Auth.logout = () => signOut();
			checkAuth().catch(Log.error());
		},
		[checkAuth, signOut],
	);

	const signIn = React.useCallback(
		(username: string, password: string) =>
			(IS_INTERPRETER ? loginInterpreter : loginClient)(unaccent(username), password, locale)
				.then(({authToken, accountId}) =>
					authToken
						? setAuth(authToken, accountId)
							.then(checkAuth)
						: Log.error("loginFailed")(),
				),
		[checkAuth, locale],
	);

	const getSetting = <S extends Setting>(key: S["key"], context: S["context"]): S | undefined =>
		settings.find(s => s.key === key && s.context === context) as S | undefined;

	const updateSetting = (updated: Setting): Promise<void> =>
		(IS_INTERPRETER
			? updateInterpreterSetting(updated as InterpreterSetting)
			: updateClientSetting(updated as RequesterSetting)
		).then(() => {
			setSettings(prev => {
				const index = prev.findIndex(s => s.settingId === updated.settingId);
				return [...prev.slice(0, index), updated, ...prev.slice(index + 1)];
			});

			Log.success("updateSettingsSuccess")();
		}).catch(Log.error("updateSettingsFailed"));

	const requestNotificationsPermissions = React.useCallback(
		() => NotificationManager.requestPermissions()
			.then((requestedPermissions) => {
				toast({
					key: `notificationsPermissions${requestedPermissions ? "Enabled" : "Disabled"}`,
					severity: requestedPermissions ? "success" : "error",
				});

				if (requestedPermissions) {
					if (IS_INTERPRETER) {
						return getInterpreterNotificationTargets(defaultPagination)
							.then(notificationTargets => {
								if (notificationTargets.items
									.filter(notificationTarget =>
										isPushChannel(notificationTarget.channel) &&
											notificationTarget.id !== NotificationManager.getThisTargetInfos().id)
									.length === 1) {
									setNotificationsStatus("anotherDevice");
								} else {
									setNotificationsStatus("granted");
								}
							});
					} else {
						setNotificationsStatus("granted");
					}
				} else {
					setNotificationsStatus("denied");
				}
			}),
		[],
	);

	const replacePushDevice = React.useCallback(
		(accountId: Authentified["accountId"]) =>
			getInterpreterNotificationTargets(defaultPagination)
				.then(notificationTargets =>
					 Promise.all([
						deleteOtherPushDevices(
							accountId,
							notificationTargets.items
								.filter(notificationTarget =>
									isPushChannel(notificationTarget.channel))),
						createNotificationsTarget(),
					 ])
						.then(() => setNotificationsStatus("granted")),
				),
		[createNotificationsTarget, deleteOtherPushDevices],
	);

	return (
		<AuthentifiedContext.Provider
			value={{
				accountId: authentified?.accountId,
				authToken: authentified?.authToken,
				checkAuth,
				identity: authentified?.identity,
				notificationsStatus,
				replacePushDevice,
				requestNotificationsPermissions,
				setAuthentified,
				settings: {
					errorMessage, getSetting, loading, updateSetting,
				},
				signIn,
				signOut,
			}}>
			{children}
		</AuthentifiedContext.Provider>
	);
};

const styles = StyleSheet.create({
	alertContainer: {
		alignItems: "center",
		justifyContent: "center",
		paddingHorizontal: DEFAULT_SPACING,
		paddingTop: DEFAULT_SPACING,
	},
});
